import React, { useRef, useState } from "react";
import { BsFillPinMapFill } from 'react-icons/bs';
import { AiOutlinePhone, AiOutlineMail } from 'react-icons/ai';
import { Link } from "react-router-dom";
import { Link as LinkRoll } from "react-scroll";

import emailjs from 'emailjs-com';
import './ContactForm.css';

const ContactForm = () => {
    const form = useRef();
    const [buttonText, setButtonText] = useState('Submit'); // Initialize button text state

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm("service_bdfl0ie", "template_mbw13eo", form.current, 'CLcHWAKSemVMd1_sU')
            .then((result) => {
                setButtonText('Message Sent'); // Change button text to "Message Sent"
                setTimeout(() => setButtonText('Submit'), 3000); // Reset button text after 3 seconds
            }, (error) => {
                setButtonText('Failed to send. Try again.'); // Show error message
                setTimeout(() => setButtonText('Submit'), 3000); // Reset button text after 3 seconds
            });

        e.target.reset();
    };

    return (
        <div className="contact-form">
            <div className="contact-header container">
                <h1>DO YOU NEED URGENT HELP?</h1>
                <p>Choosing to embark on the path of detoxification is a significant step toward reclaiming your life from substance abuse. Remember, you don't have to go through this journey alone. Seeking professional help and support from loved ones can make a world of difference. If you have any further questions or concerns, don't hesitate to reach out to our dedicated team of experts. Your journey to recovery is important, and we're here to help you every step of the way.</p>
            </div>

            <section className="container">
                <div className="contact-form-inner">
                    <h1>Get In Touch</h1>
                    <p><i>We'll keep your contact information strictly confidential.</i></p><br />
                    <form ref={form} onSubmit={sendEmail}>
                        <div className="inputs">
                            <input 
                                type="text" 
                                name='name' 
                                placeholder='Your Name' 
                                required 
                                pattern="[A-Za-z\s]+" 
                                title="Name should only contain letters and spaces." 
                            /> <br />
                            <input 
                                type="email" 
                                name='email' 
                                placeholder='Your Email' 
                                required 
                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" 
                                title="Please enter a valid email address." 
                            /> <br />
                            <input 
                                type="tel" 
                                name='phone' 
                                placeholder='Your Phone' 
                                required 
                                pattern="[0-9]{10,15}" 
                                title="Phone number should contain only numbers and be 10-15 digits long." 
                            /> <br />
                            <input 
                                type="text" 
                                name='subject' 
                                placeholder='Subject' 
                                required 
                                minLength="3" 
                                title="Subject should be at least 3 characters long." 
                            /> <br />
                        </div>
                        <div>
                            <textarea 
                                name="message" 
                                placeholder="How can we help?" 
                                cols="30" 
                                rows="10" 
                                required
                                minLength="10" 
                                title="Message should be at least 10 characters long."
                            ></textarea>

<div className="disclaimer">
                  <p>Elmo Detox needs the contact information you provide to us to contact you about our products and services. You may unsubscribe from these communications at any time. For information on how to unsubscribe, as well as our privacy practices and commitment to protecting your privacy, please review our  
                  <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                      <Link to="/privacy-policy"><span> Privacy Policy </span></Link>
                  </LinkRoll></p>
                </div>


                            <input type="submit" value={buttonText} /> {/* Button text controlled by state */}
                        </div>
                    </form>
                </div>
            </section>

            <div className="contact-cards-container">
                <div className="contact-card">
                    <h2>CALL US</h2>
                    <p><AiOutlinePhone /><a href="tel:3234439939"> +1 (323) 443-9939</a></p>
                </div>

                <div className="contact-card">
                    <h2>EMAIL</h2>
                    <p><AiOutlineMail /> Info@ElmoDetox.com </p><br />
                </div>

                <div className="contact-card">
                    <h2>LOCATION</h2>
                    <p><BsFillPinMapFill /> 4642 St Elmo Dr, Los Angeles, CA, 90019 </p>
                </div>
            </div>
        </div>
    );
}

export default ContactForm;
